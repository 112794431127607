// SocialMediaSelection.tsx
import React from 'react';
import { IoLogoYoutube } from 'react-icons/io5';
import { FaTelegramPlane, FaTiktok } from 'react-icons/fa';
import { handleSocialMediaClick } from '../../../../util/campaign/createCampaign';

interface Props {
    socialMedia: string;
    setIsPublic: React.Dispatch<React.SetStateAction<string>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const CampaignTypeSelection: React.FC<Props> = ({ socialMedia, setIsPublic, setPages }) => {
    return (
        <div className="fadeIn">
            <p className='question'> Select Social Media</p>
            <div className='youTubeTelegram'>
                <button className={socialMedia === "youtube" ? "youTubeButton youTubeButtonClicked" : "youTubeButton"} onClick={() => { setIsPublic("true"); setPages(1)}} style={{ display: "flex",flexDirection:'column', alignItems: "center", justifyContent: "space-between",background:'#090D25' }}>
                 
                    <p style={{fontSize:30}}>Let Creators Come to you</p>
                    <p style={{color:'gray',fontSize:14}}>Creators will apply to your campaign</p>
                </button>
                <button className={socialMedia === "telegram" ? "telegramButton telegramButtonClicked" : "telegramButton"} onClick={() => { setIsPublic("false"); setPages(1) }} style={{ display: "flex",flexDirection:'column', alignItems: "center", justifyContent: "space-between",background:'#090D25'  }}>
               
                    <div>Select Creators</div>
                    <p style={{color:'gray',fontSize:14}}> You will select creators </p>

                </button>


            </div>

            {/* <button className="tikTokButton" onClick={() => {}} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FaTiktok size={50} style={{ marginRight: "5px" }} />
                </div>
                <div>TikTok</div>
            </button> */}
        </div>

    );
};

export default CampaignTypeSelection;
