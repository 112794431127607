// CreateCampaignNew.tsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ConfettiExplosion from 'react-confetti-explosion';
import SocialMediaSelection from './components/socialMediaSection';
import PlanSelection from './components/planSection';
import NicheSelection from './components/nicheSection';
import MediaUpload from './components/mediaUpload';
import DateSelection from './components/dateSection';
import CampaignDescription from './components/campaignDescription';
import { url } from '../../../util/constant';
import MegaNichSelection from './components/megaNichSection';
import ExpectedView from './components/expectedView';
import SelectedCreators from './components/selectedCreators';
import WebsiteCollector from './components/WebsiteCollector';
import ErrorComponent, { ErrorComponentProps } from './components/ErrorComponent';
import PaymentSection from './components/PaymentSection';
import { useNavigate, useNavigation } from 'react-router-dom';
import { fileUpload } from '../../create_account/controller.create_account';
import axiosInstance from '../../../axiosConfig';
import { SearchCreators } from './components/searchCreators';
import congraImage from '../../../component/assets/cong.png';
import { Loading } from '../../../component/loading/loading';
import { SearchCreatorsTelegram } from './components/searchTelegramCreators';
import CampaignTypeSelection from './components/campaignType';

const CreateCampaignNew: React.FC = () => {
  const [pages, setPages] = useState<number>(0);
  const [socialMedia, setSocialMedia] = useState<string>('');
  const [isPublic,setIsPublic] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const [megaNich, setMegaNich] = useState<string>('');
  const [nichList, setNichList] = useState<any[]>([]);
  const [selectedNich, setSelectedNich] = useState<number>(0);
  const [fileObjects, setFileObjects] = useState<any[]>([]);
  const [videoLength, setVideoLength] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isExploding, setIsExploding] = useState<boolean>(false);
  const [expectedView, setExpectedView] = useState<string>('');
  const [selectedCreatorList, setSelectedCreatorList] = useState<any[]>([]);
  const [selectedCreator, setSelectedCreator] = useState<any[]>([]);
  const [loadingCreators, setLoadingCreators] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorObject, setErrorObject] = useState<ErrorComponentProps>();
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [campaignPhoto, setCampaignPhoto] = useState<string>('');
  const [campaignId, setCampaignId] = useState<number>(0);
  const [selectedCreatorListPlus, setSelectedCreatorListPlus] = useState<any[]>([]);
  const [postDescription, setPostDescription] = useState<string>('');
  const [numberOfDays,setNumberOfDays] = useState<number>(0);
  const navigation = useNavigate();




  useEffect(() => {
    // let campaignBody = getCampaignBody(selectedCreatorListPlus);
    // var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    // setLoadingCreators(true);
    // axios.post(`${url}/business/campaign/create/new`, campaignBody, {
    //   headers: {
    //     "Authorization": "Bearer " + userInfo
    //   }
    // }).then((res) => {
    //   console.log("Campaign created: ", res.data)
    //   let arrayCreatorList = convertDictToArray(res.data.creatorList);
    //   setSelectedCreatorList(arrayCreatorList);
    //   setLoadingCreators(false);
    //   setPages(pages + 1);

    //   setCampaignId(res.data.campaignId);

    // }).catch((err) => {
    //   console.log(err)
    // });
  }, [selectedCreatorListPlus])


  const [websiteList, setWebsiteList] = useState<any[]>([{
    "type": "social_media",
    "website": "YouTube",
    "url": ""
  }]);
  const shouldRenderConfetti = true;

  const handleConfettiComplete = () => {
    setIsExploding(false);
  };

  const getCampaignBody = (selectedCreators: any[] = []) => {
    return {
      socialMediaId: socialMedia == "youtube" ? 1 : socialMedia == "tiktok" ? 2 : 3,
      planId: plan == "standard" ? 1 : plan == "plus" ? 2 : 3,
      nichId: selectedNich,
      videoUrl: campaignPhoto,
      photoUrl: campaignPhoto,
      expectedViews: expectedView.split(",").join(""),
      videoLength,
      startDate: selectedDate || new Date().toISOString().split('T')[0],
      socialMediaLinks: websiteList,
      selectedCreatorList: selectedCreators,
      postDescription: postDescription,
      expectedDays: numberOfDays.toString(),
      isPublic: isPublic,
      description,
    };
  }

  // const navigation = useNavigate();

  function convertDictToArray(data: any) {
    const result = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        value.key = key;
        result.push(value);
      }
    }
    return result;
  }

  useEffect(() => {
    let megaNichId = 4;
    if (megaNich == "entertainment") {
      megaNichId = 4
    }
    axiosInstance.get(`${url}/creator/get_nich_by_mega_nich/${megaNichId}`).then((res) => {
      console.log("NICH LIST: ", res)
      setNichList(res.data)
    }
    ).catch((err) => {
      console.log(err)
    })
  }, [megaNich])

  useEffect(() => {
    console.log("websiteList: ", websiteList)
  }, [websiteList])

  useEffect(() => {
      if(pages == 1) {
        setSocialMedia("");
      }
  },[pages])


  const renderCurrentPage = () => {
    if(pages == 0){
      return (
        <CampaignTypeSelection socialMedia={socialMedia} setIsPublic={setIsPublic} setPages={setPages} />
      )
    }

    if (pages < 3) {
      if (socialMedia != "telegram") {
        return pages === 1 ?
          (<SocialMediaSelection socialMedia={socialMedia} setSocialMedia={setSocialMedia} setPages={setPages} />)
          : pages === 2 && socialMedia != "telegram" && (
            <PlanSelection plan={plan} setPlan={setPlan} setPages={setPages} />
          )

      }
    }
    if (plan == "standard") {
      return pages === 3 ?
        (
          <MegaNichSelection niche={megaNich} setMegaNich={setMegaNich} setPages={setPages} />
        ) : pages === 4 && (plan == "standard") ? (
          <NicheSelection nichList={nichList} selectedNich={selectedNich} setSelectedNich={setSelectedNich} megaNich={megaNich} />
        ) : (pages === 5 && plan == "standard") ? (
          <MediaUpload fileObjects={fileObjects} setFileObjects={setFileObjects} setVideoLength={setVideoLength} socialMedia={socialMedia} setCampaignPhoto={setCampaignPhoto} setFileLoading={setLoading} />
        ) : (pages === 6 && plan == "standard") ? (
          <CampaignDescription setPostDescription={setPostDescription} selectedDate={selectedDate} setSelectedDate={setSelectedDate} description={description} setDescription={setDescription} setWebsiteList={setWebsiteList} websiteList={websiteList} isPublic={isPublic} />
        ) : (pages === 7 && plan == "standard") ? (
          <ExpectedView expectedView={expectedView} setExpectedView={setExpectedView} selectedNiche={selectedNich} />
        ) : (pages === 6 ? (
          <WebsiteCollector WebsiteList={websiteList} setWebsiteList={setWebsiteList} setPages={setPages} />
        ) :
          pages === 8 && plan == "standard" ? (
            <SelectedCreators creators={selectedCreatorList} setErrorObject={setErrorObject} setSelectedCreatorList={setSelectedCreatorList} setPages={setPages} campaignId={campaignId} loading={loadingCreators} setLoading={setLoadingCreators} nichId={selectedNich} />
          ) : (pages === 10 && plan == "standard") ?
            (<PaymentSection setPaymentInfo={setPaymentInfo} campaignId={campaignId} />) :
            pages === 7 && (
              <SearchCreators setSelectedCreatorList={setSelectedCreatorListPlus} socialMedia={socialMedia == "telegram" ? 3 : 1} isPublic={isPublic} />
            ));

    } else if (plan == "plus" && socialMedia == "youtube") {

      if (pages === 3) {
        return (
          <CampaignDescription setPostDescription={setPostDescription} selectedDate={selectedDate} setSelectedDate={setSelectedDate} description={description} setDescription={setDescription} setWebsiteList={setWebsiteList} websiteList={websiteList} isPublic={isPublic} />
        );
      }
      if (pages === 4) {
        return (

          <MediaUpload fileObjects={fileObjects} setFileObjects={setFileObjects} setVideoLength={setVideoLength} socialMedia={socialMedia} setCampaignPhoto={setCampaignPhoto} setFileLoading={setLoading} />
        )
      }

      if (pages === 5) {
        return (
          <SearchCreators setSelectedCreatorList={setSelectedCreatorListPlus} socialMedia={0} isPublic={isPublic} />
        );
      }
      if (pages === 6) {
        return (
          <PaymentSection setPaymentInfo={setPaymentInfo} campaignId={campaignId} />
        )
      }



    } else if (socialMedia == "telegram") {
      if (pages === 2) {
        return (<MegaNichSelection niche={megaNich} setMegaNich={setMegaNich} setPages={setPages} />
        )
      }
      if (pages === 3) {
        return (
          <NicheSelection nichList={nichList} selectedNich={selectedNich} setSelectedNich={setSelectedNich} megaNich={megaNich} />
        )
      }
      if (pages === 4) {
        return (
          <CampaignDescription setPostDescription={setPostDescription} selectedDate={selectedDate} setSelectedDate={setSelectedDate} description={description} setDescription={setDescription} setWebsiteList={setWebsiteList} websiteList={websiteList} isPublic={isPublic} />
        );
      }
      if (pages === 5) {
        return (
          <MediaUpload fileObjects={fileObjects} setFileObjects={setFileObjects} setVideoLength={setVideoLength} socialMedia={socialMedia} setCampaignPhoto={setCampaignPhoto} setFileLoading={setLoading} />

        );
      }

      if (pages === 6) {
        return (
          <SearchCreatorsTelegram setSelectedCreatorList={setSelectedCreatorListPlus} socialMedia={2} isPublic={isPublic} setPageNumber={setNumberOfDays} />
        );
      }
      if (pages === 7) {
        return (
          <PaymentSection setPaymentInfo={setPaymentInfo} campaignId={campaignId} />
        )
      }
    }
    else {
      return <>
        <h1 style={{ fontSize: 33 }}>Coming Soon</h1>
        <img src="https://www.shutterstock.com/image-vector/movie-cinema-items-collection-set-600nw-2157509395.jpg" alt="cinema" width="100%" height="100%" />
      </>
    }
  };

  return (
    <div style={{width: '100%',justifyContent:'center',alignItems:'center',display:'flex'}}>

      <div className='campaignContainer'>
        {renderCurrentPage()}

        {loading && <Loading loading={loading} setLoading={setLoading} />}

        {errorObject &&
          <ErrorComponent {...errorObject} />
        }
        {pages == 11 && (
          <>
            <ConfettiExplosion onComplete={handleConfettiComplete} />
            <div className="confettiText" style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <h1>Campaign Created Successfully</h1>
              <img src={congraImage} alt="celebration" width={400} height={400} />

            </div>
            <button className="bottomButtons" onClick={() => {
              navigation("/dashboard/business/campaignDetail/" + campaignId);
            }}>Let's Go</button>
          </>
        )}

        {/* { true &&
          <PaymentSection />
        } */}

        <div className="navigateButtons">
          {(pages <= 7 && plan == "plus") &&
            <button className="bottomButtons" onClick={() => setPages(pages - 1)}>Back</button>
          }
          {(pages > 1 && pages < 9 && plan == "standard") &&
            <button className="bottomButtons" onClick={() => {
              console.log("page: ", pages - 1);
              setPages(pages - 1)
            }}>Back</button>
          }

          {(pages > 1 && pages < 7 && socialMedia == "telegram") &&
            <button className="bottomButtons" onClick={() => setPages(pages - 1)}>Back</button>
          }

          {/* ***************************** standard Next button start ******************************** */}
          {pages === 4 && plan == "standard" &&
            <button className="bottomButtons" onClick={() => {

              let nextPage_is_valid = true;
              if (pages == 4 && selectedNich == 0) {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "You Must Select a niche to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (pages == 4 && selectedNich != undefined) {
                nextPage_is_valid = true;
                setPages(pages + 1);
              }
            }}>
              Next
            </button>
          }



          {pages === 5 && plan == "standard" &&
            <button className="bottomButtons" onClick={() => {

              let nextPage_is_valid = true;
              if (pages == 5 && fileObjects.length == 0) {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "File Must be uploaded to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (pages == 5 && parseInt(videoLength + 10) > 45) {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Video must be less than 45 seconds",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else {
                nextPage_is_valid = true;
                setPages(pages + 1);
              }
            }}>
              Next
            </button>
          }

          {pages === 6 && plan == "standard" &&
            <button className="bottomButtons" onClick={() => {

              let nextPage_is_valid = true;
              if (pages == 6 && selectedDate == '' && isPublic == "false") {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Please select a start date to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (pages == 6 && description == '') {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Please Add a description to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else {
                nextPage_is_valid = true;
                setPages(pages + 1);
              }
            }}>
              Next
            </button>
          }

          {pages === 7 && plan == "standard" &&
            <button className="bottomButtons" onClick={() => {

              let nextPage_is_valid = true;
              if (pages == 7 && parseInt(expectedView.split(",").join("")) < 1000) {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "View Must be greater than 1000",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (pages == 7 && expectedView == '') {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Please Add an expected view count to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else {
                nextPage_is_valid = true;
                setPages(pages + 1);
                let campaignBody = getCampaignBody();
                var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
                setLoadingCreators(true);
                axios.post(`${url}/business/campaign/create/new`, campaignBody, {
                  headers: {
                    "Authorization": "Bearer " + userInfo
                  }
                }).then((res) => {
                  console.log("Campaign created: ", res.data)
                  let arrayCreatorList = convertDictToArray(res.data.creatorList);
                  setSelectedCreatorList(arrayCreatorList);
                  setLoadingCreators(false);
                  setCampaignId(res.data.campaignId);

                }).catch((err) => {
                  console.log(err)
                });
              }
            }}>
              Submit
            </button>
          }

          {/* ***************************** standard Next button end ******************************** */}
          {/* ***************************** plus youtube Next button start ******************************** */}
          {pages === 3 && plan == "plus" && socialMedia == "youtube" &&
            <button className="bottomButtons" onClick={() => {
              setPages(pages + 1);
            }}>
              Next
            </button>
          }


          {pages === 4 && plan == "plus" && socialMedia == "youtube" &&
            <button className="bottomButtons" onClick={() => {
              if(isPublic == "false") {
                setPages(pages + 1);
              }else{
              
                let campaignBody = getCampaignBody(selectedCreatorListPlus);
                var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
                setLoadingCreators(true);
                axios.post(`${url}/business/campaign/create/new`, campaignBody, {
                  headers: {
                    "Authorization": "Bearer " + userInfo
                  }
                }).then((res) => {
                  console.log("Campaign created: ", res.data)
                  let arrayCreatorList = convertDictToArray(res.data.creatorList);
                  setSelectedCreatorList(arrayCreatorList);
                  setLoadingCreators(false);
                  setPages(pages + 2);
  
                  setCampaignId(res.data.campaignId);
  
                }).catch((err) => {
                  console.log(err)
                });
              }


            }}>
              Next
            </button>
          }

          {pages === 5 && plan == "plus" && socialMedia == "youtube" &&
            <button className="bottomButtons" onClick={() => {

              if(selectedCreatorListPlus.length == 0 && isPublic == "false") {
              setErrorObject({
                errorTitle: "warning",
                errorMessage: "You Must Select at list one Creator to continue",
                posetiveAction: () => {
                  setErrorObject(undefined);
                },
                negativeAction: () => {
                  setErrorObject(undefined);

                },
                type: "warning",
                posetiveText: "Ok",
                negativeText: "Cancel"

              })
              
            }else{
              setPages(pages + 1);
              let campaignBody = getCampaignBody(selectedCreatorListPlus);
              var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
              setLoadingCreators(true);
              axios.post(`${url}/business/campaign/create/new`, campaignBody, {
                headers: {
                  "Authorization": "Bearer " + userInfo
                }
              }).then((res) => {
                console.log("Campaign created: ", res.data)
                let arrayCreatorList = convertDictToArray(res.data.creatorList);
                setSelectedCreatorList(arrayCreatorList);
                setLoadingCreators(false);
                setPages(pages + 1);

                setCampaignId(res.data.campaignId);

              }).catch((err) => {
                console.log(err)
              });
          }}}>
              Next
            </button>
          }

          {pages === 6 && plan == "plus" && socialMedia == "youtube" &&
            <button
              className="bottomButtons"
              onClick={() => {
                var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

                axios.post(`${url}/campaign/pay/business`, paymentInfo, {
                  headers: {
                    "Authorization": "Bearer " + userInfo
                  }
                })
                  .then((res) => {
                    console.log('Campaign created:', res);
                    setPages(11);
                    setIsExploding(true);

                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Create Campaign
            </button>

          }
          {/* ***************************** plus youtube Next button ended ******************************** */}
          {/* ***************************** telegram  button start ******************************** */}





          {pages === 3 && socialMedia == "telegram" &&
            <button className="bottomButtons" onClick={() => {

              if (selectedNich == 0) {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "You Must Select a niche to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (selectedNich != undefined) {

                setPages(pages + 1);
              }
            }}>
              Next
            </button>
          }
          {pages === 4 && socialMedia == "telegram" &&
            <button className="bottomButtons" onClick={() => {
              if (selectedDate == '' && isPublic == "false") {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Please select a start date to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else if (description == '') {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "Please Add a description to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else {
                setPages(pages + 1);
              }
            }}>
              Next
            </button>
          }

          {pages === 5 && socialMedia == "telegram" &&
            <button className="bottomButtons" onClick={() => {
              setPages(pages + 1);
            }}>
              Next
            </button>
          }
          {pages === 6 && socialMedia == "telegram" &&
            <button className="bottomButtons" onClick={() => {
              // setPages(pages + 1);

              if (selectedCreatorListPlus.length == 0 && isPublic == "false") {
                setErrorObject({
                  errorTitle: "warning",
                  errorMessage: "You Must Select at list one Creator to continue",
                  posetiveAction: () => {
                    setErrorObject(undefined);
                  },
                  negativeAction: () => {
                    setErrorObject(undefined);

                  },
                  type: "warning",
                  posetiveText: "Ok",
                  negativeText: "Cancel"

                })
              } else {
                let campaignBody = getCampaignBody(selectedCreatorListPlus);
                console.log("Campaign Body: ", campaignBody);
                var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
                setLoadingCreators(true);
                axios.post(`${url}/business/campaign/create/new`, campaignBody, {
                  headers: {
                    "Authorization": "Bearer " + userInfo
                  }
                }).then((res) => {
                  console.log("Campaign created: ", res.data)
                  let arrayCreatorList = convertDictToArray(res.data.creatorList);
                  setSelectedCreatorList(arrayCreatorList);
                  setLoadingCreators(false);
                  setPages(pages + 1);

                  setCampaignId(res.data.campaignId);

                }).catch((err) => {
                  console.log(err)
                });
              }


            }}>
              Next
            </button>
          }

          {pages === 7 && socialMedia == "telegram" &&
            <button
              className="bottomButtons"
              onClick={() => {
                var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

                axios.post(`${url}/campaign/pay/business`, paymentInfo, {
                  headers: {
                    "Authorization": "Bearer " + userInfo
                  }
                })
                  .then((res) => {
                    console.log('Campaign created:', res);
                    setPages(11);
                    setIsExploding(true);

                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }}
            >
              Create Campaign
            </button>

          }





























        </div>

      </div>

      {(pages === 10 && plan == "standard") || (pages == 8 && plan == "plus") && (
        <div className="fadeIn">
          <button
            className="bottomButtons"
            onClick={() => {
              var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

              axios.post(`${url}/campaign/pay/business`, paymentInfo, {
                headers: {
                  "Authorization": "Bearer " + userInfo
                }
              })
                .then((res) => {
                  console.log('Campaign created:', res);
                  setPages(11);
                  setIsExploding(true);

                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          >
            Create Campaign
          </button>
        </div>
      )}


    </div>
  );
};

export default CreateCampaignNew;
