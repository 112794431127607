import axios from "axios"
import { url } from "../../../util/constant"
import axiosInstance from "../../../axiosConfig";


export function getTotalView(){
    return {
        title: "Total Views",
        amount: "1,600",
        icon: "fa fa-eye"
    }
}
export interface Campaign {
    ExpectedView: number;
    viewCount: number | null;
    CampaignId: number;
}

export interface EarningsData {
    totalEarnings: {
        TotalEarnings: number;
    };
    montlyEarnings: {
        TotalEarnings: number;
        Month: string;
    }[];
    availableEarningsForCashOut: {
        TotalEarnings: number | null;
    };
}

export interface CampaignStats {
    TotalCampaigns: string;
    AcceptedCampaigns: string;
    PendingCampaigns: string;
}


export function getTotalDashboardData(): Promise<CampaignStats>{
    
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/creator/dashboarad/data/all', {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("TESTc: " ,response.data);
            resolve(response.data[0]);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        }); 
    
}

export function getEarningData(): Promise<EarningsData>{
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/creator/dashboarad/data/earning', {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("TESTc: " ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });  
}


export function getExpectedAndActualViews(): Promise<Campaign[]>{

var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

return new Promise((resolve, reject) => {
    axiosInstance.get(url+'/creator/dashboarad/data/perorder', {
      headers: {
          "Authorization": "Bearer " + userInfo   
      }
  })
      .then(function (response) {
        console.log("TESTc: " ,response.data);
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
      resolve(error);
      });
    }); 
}