import React from "react";
import { AiFillApi, AiFillCalculator, AiFillPayCircle, AiOutlinePayCircle } from "react-icons/ai";
import "./styles/style.orders.css";
import { AlignVerticalDistributeCenter } from "lucide-react";
import { CircularProgress } from "@mui/material";
import { BsCameraReels, BsPersonGear } from "react-icons/bs";
import { Ri24HoursFill, RiMoneyCnyBoxFill, RiMoneyDollarBoxLine, RiMoneyDollarCircleFill } from "react-icons/ri";
import { MdDoneOutline } from "react-icons/md";
import { IoCheckmarkDone } from "react-icons/io5";
import { TbBusinessplan, TbCameraQuestion } from "react-icons/tb";

interface campaignDetailStatusProp {
  status: string;
}

export default function OrderStatus({ status }: campaignDetailStatusProp) {

  const getStatusNumber = (status: string) => {
    switch (status) {
      case "draft":
        return 1;
      case "draft":
        return 1;
      case "distribution":
        return 2;
      case "Payment_Verification":
        return 3;
      case "Payment_Pending":
        return 3;
      case "production":
        return 4;
      case "Completed":
        return 5;
      default:
        return 0;
    }
  }

  const getClassName = (status: string , index: number) => {
    console.log("STATUS: ",status, getStatusNumber(status) , " = = ", index);
    // if (getStatusNumber(status) == index) {
    //   return "stepper-item active";
    // } else
    if (getStatusNumber(status) >= index) {
      return "stepper-item completed";
    } else {
      return "stepper-item";
    }
  }

  return (
    <div className="stepper-wrapper">
  <div className={getClassName(status,1)}>
    <div className="step-counter">
    <AiOutlinePayCircle style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">100 Birr</div>
  </div>
  <div className={getClassName(status,2)}>
    <div className="step-counter">
    <AlignVerticalDistributeCenter style={{ color: "white", fontSize: 17 }} />
   </div>
    <div className="step-name">Distribution</div>
  </div>
  <div className={getClassName(status,3)}>
    <div className="step-counter">
    <RiMoneyDollarCircleFill style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Payment</div>
  </div>
  <div className={getClassName(status,4)}>
    <div className="step-counter">
    <BsCameraReels style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Production</div>
  </div>
  <div className={getClassName(status,5)}>
    <div className="step-counter">
    <BsPersonGear style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Progress</div>
  </div>
  <div className={getClassName(status,5)}>
    <div className="step-counter">
    <IoCheckmarkDone style={{ color: "white", fontSize: 17 }} />

    </div>
    <div className="step-name">Completed</div>
  </div>
</div>
  );
}


/*


export default function CampaignDetailStatusHeader({status}: campaignDetailStatusProp) {
  return (
    <div className="containerStatus">
      <div className="inner-container">
        <div className="headerBoxer">
          <div className={status == "draft"?  "highlighted-circle" : "circle"  }>
            <AiOutlinePayCircle style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>100 Birr</p>
        </div>
        <div className="headerBoxer">
          <div className={status == "distribution"?  "highlighted-circle" : "circle"  }>
            <AlignVerticalDistributeCenter style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Creator distribution</p>
        </div>
        <div className="headerBoxer">
          <div className={status == "waiting_payment"?  "highlighted-circle" : "circle"  }>
            <RiMoneyDollarCircleFill style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Payment</p>
        </div>
        <div className="headerBoxer">
          <div className={status == "production"?  "highlighted-circle" : "circle"  }>
            <BsCameraReels style={{ color: "white", fontSize: 30 }} />
          </div>
          <p>Production</p>
        </div>
        <div className="headerBoxer-highlighted">
        <div className={status == "progress"?  "highlighted-circle" : "circle"  }>
          <BsPersonGear style={{ color: "white", fontSize: 40 }} />
        </div>
        <p>Progress</p>
        </div>
        <div className="headerBoxer">
          <div className="circle">
            <IoCheckmarkDone style={{ color: "white", fontSize: 40 }} />
          </div>
          <p>Completed</p>
        </div>
      </div>
    </div>
  );
}

*/