// src/context/NavigationContext.js
import React, { createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext("NavigationContext");

export const NavigationProvider = ({ children }: any) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handle403 = () => {
      // add expired object which has the url of the page that the user was trying to access
      let expired = {
        url: window.location.pathname,
      };
      //{"access_token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsInVzZXJOYW1lIjoiYW5vdGhlclRlc3QiLCJwYXNzd29yZCI6ImFub3RoZXJUZXN0MiIsInVzZXJUeXBlIjoiY3JlYXRvciIsImlhdCI6MTczMjI3NDMwNiwiZXhwIjoxNzMyMzEwMzA2fQ.q9avk-YcLeEMsHpfV3i_-U10AFPrTPqOOTm_a3Pzph4","body":{"id":13,"userName":"anotherTest","password":"anotherTest2","userType":"creator"},"creatorDetail":{"id":7,"user":13,"firstName":"anotherTest","lastName":"anotherTest","dob":"2001-01-11","gender":"male","email":"tewedaj13@gmail.com","verfiedEmail":"true","phoneNumber":"0919911616","woreda":"addis","subCity":"addist14","city":"addis2","isActive":1,"telegramId":"1105819889","region":"addiszAnother","bankName":"CBES","bankUserName":"Tewedaj","bankAccountNumber":"100056456","tinNumber":"76768787","licence":null,"about":"This is a test about text, this will be updated using the profile page  once it is implemented, it is implemented now baby","rate":"1","productionCost":"3000","isTest":"true","identificationImage":"http://img.sugarcap.org/livebbt/1723536684375tewedaj.jpeg"}}
      let user = JSON.parse(localStorage.getItem("UserInfo") || "{}");

     // let expiredGet : any = JSON.stringify(localStorage.getItem("expired"))
      // if (expiredGet && expiredGet?.url == expired.url) {
     
      //     localStorage.setItem("expired", "");
        
      // }else{

          // if userType is creator then go to /creator_login
       //   localStorage.setItem("expired", JSON.stringify(expired));
      // }
     
      

      if (user?.body.userType === "creator") {
        // navigate('/creator_login');
        localStorage.setItem("expiredC", JSON.stringify(expired));

        window.location.href = "/creator_login";

      } else {
        // if userType is business then go to /business_login
        // navigate('/business_login');
        localStorage.setItem("expiredB", JSON.stringify(expired));

        window.location.href = "/business_login";
      }
      //navigate('/forbidden');
    };
    
    window.addEventListener('axios403', handle403);

    return () => {
      window.removeEventListener('axios403', handle403);
    };
  }, [navigate]);

  return (
    <NavigationContext.Provider value={""}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => useContext(NavigationContext);
