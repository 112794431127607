

import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { View } from "lucide-react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../../util/constant";
import { promises } from "dns";
import { AiFillDollarCircle, AiOutlineFundView } from "react-icons/ai";
import { BsTicketDetailed } from "react-icons/bs";
import { BiSolidDetail } from "react-icons/bi";
import axiosInstance from "../../../axiosConfig";

export function getColumns(): GridColDef[] {    
 const columns: GridColDef[] = [
  { field: '#', headerName: '#', width: 50,
  renderCell: (params:any) => {
              
      return(
          <span className="description" >
              {params.value}
          </span>
      );   
   }},
    { field: 'contentDescription', headerName: 'Description', width: 230,
  renderCell: (params:any) => { 
              
      return(
          <span className="description" >
              {params.value}
          </span>
      );   
   }},
    // { field: 'estimatedBudget', headerName: 'Estimated Budget', width: 150,
    //   renderCell: (params:any) => {
    //     return (
    //       <span style={{color:'brown',fontWeight:'bolder',justifyContent:'space-evenly', display:'flex',flexDirection:'row'}}>
    //         {parseInt(params.value)?.toLocaleString() }
    //         <AiFillDollarCircle style={{fontSize:23}} />
    //         </span>
    //     )
    // }
    //  },
    { field: 'totalViews', headerName: 'Estimated View', width: 150,
      renderCell: (params:any) => {
          return (
            <span style={{color:'green',fontWeight:'bolder',display:'flex',flexDirection:'row'}}>
              {params.value?.toLocaleString() }
              <AiOutlineFundView style={{fontSize:23}} />
              </span>
          )
      }
     },

    { field: 'startDate', headerName: 'Start Date', width: 130 },

    { field: 'campaignStatus', headerName: 'Status', width: 130,
        renderCell: (params:any) => {
            
            return(
                <span  className={params.value === "draft" ? "statusButton-pending" : "statusButton-active"} >
                    {params.value}
                </span>
            );
        }
},
   
    {
      field: 'id',
      headerName: 'Action',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      renderCell:  (params:any) => {
       
        
        
        return (
          <div>

            <Link  style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}} to={"/dashboard/business/campaignDetail/"+params.value} className="actionButton" >
               Detail
               <BiSolidDetail style={{fontSize:20}} />
                </Link>
           </div>
        );
    },
      width: 160,
     
    },
  ];

return columns;
}

export async function getRows(): Promise<any> {
  try {
    var res:any[] = await getCampaignList() as [];
  console.log('campaign list: ', res);
  res.map((campaign:any, index)=>{
    campaign['#'] = index+1;
  })
  res.sort((a,b)=>{
    return b['#']-a['#'];
  });
  res.map((campaign:any, index)=>{
    campaign['#'] = index+1;
  });
  return res;
  } catch (error) {
    return [];
  }

  
}

export function getCampaignList(){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/business/campaigns',{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("TEST: " ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}


export function getCampaignVideoDetail(creatorId: number , campaignId: number, socialMediaAccountId: number = 0){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+`/campaign/creator/${creatorId}/campaign/${campaignId}/${socialMediaAccountId}`,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}

export function getYouTubeVideoId(url:string): string{
  // Regular expression to match YouTube video ID
  var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  var match = url.match(regExp);

  if (match && match[1]) {
      var id = match[1]; // return the video ID
      return 'https://www.youtube.com/embed/'+id;
  } else {
      return ""; // return null if the URL is not a valid YouTube URL
  }
}


export function getCampaignDetail(id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/business/campaign/'+id,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}

export function calculateBudget(id: number) {
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/business/campaign/calculate_budget/'+id,{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });

}


export function calculateTotalProgress(expectedView: number , actualView: number){
  return (actualView/expectedView)*100;
}


export function addPayment(paymentData: any){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
  return new Promise((resolve, reject) => {
      axios.post(url+'/business/campaign/add_payment',paymentData,{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
        
    },
  )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });
}

export function addCreatorToExistingCampaign(creatorContent: any){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
  return new Promise((resolve, reject) => {
      axiosInstance.post(url+'/business/campaign/add_creator_to_campaign',creatorContent,{
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      });
}