import React, { useState } from "react";

type SocialMediaAccount = {
  id: number;
  creatorId: number;
  socialMediaId: number;
  accountName: string;
  accountLink: string;
  subCount: number;
  avgView: string;
  avgPostPerMonth: string;
  refershToken?: string;
  quota_point?: string | null;
  channelOwnerId?: string | null;
  rate: number;
  channelId?: string | null;
  status: string;
  nicheId?: number | null;
};

type CreatorData = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  isActive: number;
  identificationImage: string;
  about: string;
  rate: string;
  productionCost: string;
  socialmediaaccounts: SocialMediaAccount[];
};

const CreatorAccordion: React.FC<any> = ({ creator }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Check if the creator has at least one social media account
  const hasSocialMediaAccounts = creator.socialmediaaccounts.length > 0;

  return (
    <div
      className={`border border-gray-300 rounded-md mb-4 shadow ${
        hasSocialMediaAccounts ? "bg-green" : "bg-white"
      }`}
      style={{backgroundColor: hasSocialMediaAccounts? 'green': 'green'}}
    >
      {/* Accordion Header */}
      <div
        className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 hover:bg-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <img
            src={creator.identificationImage}
            alt="Creator"
            className="h-12 w-12 rounded-full mr-4"
          />
          <div>
            <h3 className="text-lg font-semibold">
              {creator.firstName} {creator.lastName}
            </h3>
            <p className="text-sm text-gray-500">{creator.email}</p>
            <p style={{color:'green',fontWeight:'bolder',fontSize:21}}>{hasSocialMediaAccounts && "SOCIALMEDIA CONNECTED"}</p>
          </div>
        </div>
        <span className="text-gray-600">
          {isOpen ? "▲" : "▼"}
        </span>
      </div>

      {/* Accordion Body */}
      {isOpen && (
        <div className="p-4 bg-white">
          {/* Basic Details */}
          <div className="mb-4">
            <p className="text-sm">
              <strong>Phone:</strong> {creator.phoneNumber}
            </p>
            <p className="text-sm">
              <strong>City:</strong> {creator.city}
            </p>
            <p className="text-sm">
              <strong>About:</strong> {creator.about}
            </p>
            <p className="text-sm">
              <strong>Rate:</strong> {creator.rate}
            </p>
          </div>

          {/* Social Media Accounts */}
          <div>
            <h4 className="text-lg font-semibold mb-2">Social Media Accounts</h4>
            {creator.socialmediaaccounts.map((account: any) => (
              <div
                key={account.id}
                className="p-4 mb-2 border border-gray-200 rounded-md shadow"
              >
                <p>
                  <strong>Account Name:</strong> {account.accountName}
                </p>
                <p>
                  <strong>Account Link:</strong>{" "}
                  <a
                    href={account.accountLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    {account.accountLink}
                  </a>
                </p>
                <p>
                  <strong>Subscribers:</strong> {account.subCount}
                </p>
                <p>
                  <strong>Average View:</strong> {account.avgView}
                </p>
                <p>
                  <strong>Posts Per Month:</strong> {account.avgPostPerMonth}
                </p>
                <p>
                  <strong>Rate:</strong> ${account.rate}
                </p>
                <p>
                  <strong>Status:</strong>{" "}
                  {account.status === "true" ? "Active" : "Inactive"}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatorAccordion;
