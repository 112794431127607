import axios from "axios";
import { url } from "../../../util/constant";
import axiosInstance from "../../../axiosConfig";

export type CreatorData = {
  id: number;
  identificationImage: string;
  FirstName: string;
  LastName: string;
  totalViews: string | null;
  socialMedia: string;
  AccountName?: string;
  rate?: number;
  status?: string;
  socialMediaAccountId?: number;
  AvgView: string;
  days?: [];
  with_pin?: [];
  with_out_pin?: [];
  is_with_pin?: boolean;
  selectedDayIndex?: number;
  hours_on_top?: string;
  selectedDay?: number;
  email: string;
  phoneNumber: string;
  woreda: string;
  city: string;
  bankName: string;
  bankAccountNumber: string;
  firstName: string;
  lastName: string;
  AccountLink?: string;

};


export function getAllCreatorsData(current: number,total: number): Promise<CreatorData[]>{
    
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/creator/search/'+ current + "/" + total, {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("All Creators" ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        }); 
    
}

export function getCreatorData(current: number,total: number, name: string,socialMediaId: number): Promise<CreatorData[]>{
    
    var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/creator/search/'+ current + "/" + total + "/" + name +"/"+socialMediaId, {
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            console.log("Creator" ,response.data);
            

              resolve(response.data);
            
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        }); 
    
}


export function getCreatorDataAdmin(current: number,total: number, name: string,socialMediaId: number): Promise<CreatorData[]>{
    
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

  return new Promise((resolve, reject) => {
      axiosInstance.get(url+'/admin/get_creators', {
        headers: {
            "Authorization": "Bearer " + userInfo   
        }
    })
        .then(function (response) {
          console.log("Creator" ,response.data);
          if(response.data.status == "success"){

            resolve(response.data.data);
          }
        })
        .catch(function (error) {
          console.log(error);
        resolve(error);
        });
      }); 
  
}