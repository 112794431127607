import styled from "styled-components";





export const ForgetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #EBEFF3;
    width: 100%;
    height: 100vh;
`;

export const ForgetPasswordWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 400px;
    height: 350px;
    margin-top: 0px;
    margin-bottom: 20px;
    `;

export const ForgetPasswordTitle = styled.h1`
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    text-align: start;
`;

export const ForgetPasswordInput = styled.input`
    margin-top: 20px;
    padding: 10px;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
`;

export const ForgetPasswordButton = styled.button`
    margin-top: 20px;
    padding: 10px;
    width: 300px;
    border: 1px solid black;
    border-radius: 5px;
    background-color: #151B78;
    color: white;
`;

export const ErrorMessages = styled.p`
    color: red;
    margin-top: 10px;
    font-size: 14px;
    text-align: start;
    width: 80%;  
`;