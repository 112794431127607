import { Button, Link } from "@mui/material";
import { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { BiLogoTelegram } from "react-icons/bi";
import { RiCloseCircleFill, RiCopyleftLine, RiFileCopyLine, RiGoogleFill } from "react-icons/ri";
import { google_oauth_link } from "../../../../util/constant";
import { useNavigate } from "react-router-dom";


interface youtubeProp{
    closeyoutube: any
}

export default function YoutubeConnectOptions({closeyoutube}: youtubeProp) {
    const [minute, setMinute] = useState<number>(5); // 2 minutes
    const [second, setSecond] = useState<number>(0); // 0 seconds
    const navigation = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setSecond((prevSecond) => {
                if (prevSecond === 0) {
                    setMinute((prevMinute) => (prevMinute > 0 ? prevMinute - 1 : 0));
                    return 59;
                } else {
                    return prevSecond - 1;
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div 
           
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            background: 'rgba(0, 136, 204, 0.19)',
            width: '100%',
            height: '100%',
            zIndex: 10000,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div style={{
                width: 300,
                height: 230,
                backgroundColor: 'white',
                borderRadius: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bolder',
                zIndex: 100000,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
            }}>
                <div style={{width:'100%',height:10,position:'relative',top:0,display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>

                <RiCloseCircleFill onClick={()=>{
                closeyoutube();
                }} size={20} style={{margin:10}} />
                </div>
               <div style={{width:'80%',height:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}> 
               
                <Button onClick={()=>{
                                 // window.open(google_oauth_link);
  
                }} style={{backgroundColor:'white',borderRadius:10,border:'1px lightgray solid',color:'gray',width:'90%'}}>
                    Sign in with Google
                    {"   "}
                    <span>

                    </span>
                    <RiGoogleFill style={{margin:5}}/>
                </Button>
                <p style={{fontSize: 11,color:'gray',marginTop: 5}}>
                Connecting with Google is not available at the moment, please use the link verification system below.

Watch <a href="" style={{color:'lightblue'}} > tutorial </a> on how to verify your Youtube account
                </p>
               
               <Link onClick={()=>{
                navigation("add-Youtube-channel");
               }}  style={{fontSize:13}} >Manual Connect</Link>
               </div>
            </div>
        </div>
    );
}
