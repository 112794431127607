import { useMemo } from "react";
import "./style.orders.css";
import "./style.mobile.orders.css";
import Table from "../../../component/table/table";
import { useEffect,useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getColumns, getRows } from "./controller.orders";
import { CircularProgress } from "@mui/material";

export default function Orders(){

      const [data,setData] = useState<any>([]);
      const [notFilteredData,setNotFilteredData] = useState<any>([]);
      let isMobile = window.innerWidth < 768;

      const [columns,setColumns] = useState<GridColDef[]>([]);
      const [loading,setLoading] = useState<boolean>(false);
      const [selectedOption,setSelectedOption] = useState<string>("All");
      useEffect(()=>{
        setLoading(true);
          setColumns(getColumns());
          async function getRowsAsync(){
            var rowDataList = await getRows();
            console.log("rowdata",rowDataList);
              setData(rowDataList);
              setNotFilteredData(rowDataList);
            setLoading(false);

          }
          getRowsAsync();
      },[])
      const [optionList,setOptionList] = useState<string[]>(["All","Requested","Accepted","Rejected","Completed"]);

    return (
        <div className="orderScreen" style={{height: 900}}>
          <div className="campaignList-header">
                {optionList.map((option,index)=>{
                    if(option == selectedOption){
                        return(
                            <span key={index} className={`option-selected`}>{option}</span>
                        )
                    }
                    return(
                     
                        <span onClick={()=>{
                            if(option == "All"){
                              setData(notFilteredData);
                              setSelectedOption(option);
                              return;
                            }
                          setSelectedOption(option);

                          console.log("Data: ", data);
                        let oldData = notFilteredData;
                          oldData = oldData.filter((item:any)=>{
                            if(item.Status == option){
                              return item;
                            }
                          }
                          )
                          setData(oldData);


                        }} key={index}  className={`option`}>{option}</span>
                    )
                
                })
                }
            </div>
            <div style={{margin: 0,width: isMobile? '100%' : '110%',height:750}}>
            {loading ? <CircularProgress /> : 
            <Table columns={columns} data={data}  />
    }</div>
        </div>
    );
}