import { useEffect, useState } from "react";
import { AiOutlineLine } from "react-icons/ai";
import { RiAddLine } from "react-icons/ri";



export default function PaymentComponent({setPaymentInfo}: any) {
    const [linkType, setLinkType] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [websiteListLocalized, setWebsiteListLocalized] = useState<any[]>([{
        "days": "1",
        "hours": "1",
        "with_pin": "0",
        "with_out_pin": "0"
    },
    {
        "days": "2",
        "hours": "2",
        "with_pin": "0",
        "with_out_pin": "0"
    },
    {
        "days": "3",
        "hours": "3",
        "with_pin": "0",
        "with_out_pin": "0"
    }]);

    useEffect(()=>{
        setPaymentInfo(websiteListLocalized);
    },[websiteListLocalized])

    return (
        <div>
            <div className="website-linker-container_outer" >
                     <h3> Days </h3>
                      <h3> Hours on top</h3>
                       <h3> With Pin(Birr) </h3>
                        <h3> With out pin(Birr) </h3>
                    </div>
            {websiteListLocalized.map((collectedData: any, index: number) => {

                return (
                    <div className="website-linker-container" key={index}>
                      <input onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, days: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }} type="number" style={{width:160 , padding: 6, textAlign: 'center',  }} contentEditable={false} value={collectedData.days}  name="days" />
                      <input type="text" color={"gray"} style={{width:160 , padding: 6, textAlign: 'center',  color:'gray' }}  contentEditable={false} value={collectedData.days * 1}  name="days" />
                        <input type='number' onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, with_pin: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }}  style={{width:160 , padding: 6, textAlign: 'center',  }} name="with_pin" />
                        <input type='number' onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, with_out_pin: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }}  style={{width:160 , padding: 6, textAlign: 'center',  }} name="with_out_pin" />


                        <button onClick={() => {
                            setWebsiteListLocalized(prevState => {
                                const newState = [...prevState];
                                newState.splice(index, 1);
                                return newState;
                            });
                        }} className="negative">
                            <AiOutlineLine width={50} height={50} />
                        </button>
                        <button onClick={() => {
                            let prevValue = [...websiteListLocalized];
                            let toBeRepeated = prevValue[index];
                            prevValue.push(toBeRepeated);
                            setWebsiteListLocalized(prevValue);


                        }}>
                            <RiAddLine width={50} height={50} />
                        </button>



                    </div>
                )
            })}

        </div>
    )
}