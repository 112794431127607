import React from 'react';
import CBEQr from '../../../../component/assets/CBEQR.jpg';

interface Props {
    expectedView: string;
    setPaymentInfo: (value: any) => void;
    campaignId: number;

}

const PaymentSection: React.FC<any> = ({setPaymentInfo,campaignId}) => {


    return (
        <div className="fadeIn">
            <p className='question'>  Pay & Verify</p>
            <p style={{textAlign: 'start', color:'gray', fontSize: 17, marginBottom: 20}}> <b style={{color:'black'}}>100 Birr</b> payment is required before the campaign is started, the 100 Birr will be used for the campaign letter on however we require this to ensure a better Experiance for you   </p>
            <p style={{textAlign: 'start', color:'gray', fontSize: 17, marginBottom: 20}}>You can pay using the QR code below, once you have paid enter the code below to verify your payment </p>
          <div style={{display: 'flex' , flexDirection: 'row'}}>
            {/* <img src="https://semonegna.com/wp-content/uploads/2021/05/telebirr.jpg" alt="Telebirr" width={120} height={100} /> */}
          <img src="https://addisfortune.news/wp-content/uploads/2022/02/CBE-logo.jpg" alt="CBE Birr" width={100} height={100} />
          </div>
            <div className="payment-container">
              <div className="qr-container">
                <div>
                <h3>1000613933763</h3>
            <img src={CBEQr} alt="QR Code" width={200} height={200} />

                </div>
                </div>
                <div className='rightSide-payemt'>
                <h3>Transaction Code</h3>
                <input type='text' onChange={(e)=>{
                    setPaymentInfo({
                      "campaignId": campaignId,
                      "paymentAmount": "100",
                      "paymentBank": "TELEBIRR",
                      "transactionNumber": e.target.value,
                    })
                  
                }} placeholder="Enter the code here" className="verfication-code" />

                </div>
            </div>

       

        </div>
    );
};

export default PaymentSection;
