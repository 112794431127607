import { Router, Routes, Route, Link, useParams } from "react-router-dom";
import CreateAccount from "../create_account/create_account";
import Home from "../home/home";
import HowItWorks from "../how_it_works/how_it_works";
import DashboardHome from "../dashboard/home/dashboardHome";
import "../dashboard/style.mainDashboard.css";
import "../dashboard/style.mobile.mainDashboard.css";
import Orders from "../dashboard/orders/orders";
import logo from "../../component/assets/images/zeter adv white logo1.png";
import { AiFillHome, AiOutlineSend } from "react-icons/ai";
import { AiOutlineOrderedList } from "react-icons/ai";
import { AiFillProfile } from "react-icons/ai";
import { RiAccountCircleFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { mainDashboardProp } from "../dashboard/prop.mainDashboard";
import Order from "../dashboard/orders/order";
import Profile from "../dashboard/profile/profile";
import { userProp } from "../auth/prop.creator";
import { getUserInfo, getUserType } from "../../util/controller";
import SuccessToast from "../../component/toast/success";
import ListCampaign from "../dashboard_business/campaign/listCampaigns";
import { MdHowToReg, MdPayment, MdYoutubeSearchedFor } from "react-icons/md";
import { relative } from "path";
import Payments from "../dashboard/payment/payments";
import AdminHome from "./home/adminHome";
import Creators from "./creators/creators";
import Businesses from "./businesses/businesses";
import IncomeReport from "./income_report/incomeReport";
import PaymentRequests from "./payment_requests/paymentRequests";
import Reports from "./reports(flags)/reports";
import TrendingCampaigns from "./trending_campaigns/trendingCampaigns";
import ListPayment from "./payment_verefications/paymentVerefication";
import YoutubeUpdates from "./youtube-updates/youtubeUpdates";
import TelegramMessage from "./TelegramMessage/TelegramMessage";
import { PayCreators } from "./pay-creators/payCreators";


export default function AdminDashboard(screen: mainDashboardProp) {
  const [title, setTitle] = useState<string>("home");
  const [screenS, setScreenS] = useState<string>(screen.screen);
  const [bodyInfo, setBodyInfo] = useState<userProp>(getUserInfo());
  const [successMessage, showSuccessMessage] = useState(false);
  const [userType, setUserType] = useState<string>(getUserType());
  const { id }: any = useParams();
  useEffect(() => {
    showSuccessMessage(true);
    setTimeout(() => {
      showSuccessMessage(false);
    }, 2000);
  }, []);
  const handleClose = () => {
    showSuccessMessage(false);
  };
  return (
    <div className={"mainDashboardScreen"}>
      {successMessage && (
        <div className="toastContainer">
          <SuccessToast
            message="Logged in successfully!"
            onClose={handleClose}
          />
        </div>
      )}
      {userType != "creator" && (
        <div className={"leftNav"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              background: "transparent",
              width: "100%",
              justifyContent: "space-around",
            }}
          >
            <img src={logo} width="90px" style={{ margin: 50, width: "60%" }} />
            {/* <h2>Zeter</h2> */}
          </div>

          <ul>
            <li>
              <Link
                className={
                  screen.screen === "home" ? "navButton-selected" : "navButton"
                }
                to={"/dashboard/admin"}
              >
                <AiFillHome
                  className={
                    screen.screen === "home"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p>Home</p>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                onClick={() => {
                  setTitle("dsf");
                }}
                className={
                  screen.screen === "creators" ? "navButton-selected" : "navButton"
                }
                to={"/dashboard/admin/creators"}
              >
                <AiOutlineOrderedList
                  className={
                    screen.screen === "creators"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Creators</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "businesses"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/businesses"}
              >
                <RiAccountCircleFill
                  className={
                    screen.screen === "businesses"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Businesses</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "payment_verefication"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/payment_verefication"}
              >
                <RiAccountCircleFill
                  className={
                    screen.screen === "payment_verefication"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Payment Verefication</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "income-report"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/income-report"}
              >
                <MdPayment
                  className={
                    screen.screen === "income-report"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Income Report</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "pay-creators"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/pay-creators"}
              >
                <MdPayment
                  className={
                    screen.screen === "pay-creators"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Pay Creators</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "youtube-update"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/youtube-update"}
              >
                <MdYoutubeSearchedFor
                  className={
                    screen.screen === "youtube-update"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Update youtube content</p>
              </Link>
            </li> 
            <li>
              <Link
                className={
                  screen.screen === "telegram-message"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/telegram-message"}
              >
                <AiOutlineSend
                  className={
                    screen.screen === "telegram-message"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Telegram Message</p>
              </Link>
            </li>    
            <li>
              <Link
                className={
                  screen.screen === "payment-requests"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/payment-requests"}
              >
                <MdHowToReg
                  className={
                    screen.screen === "payment-requests"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Payment Requests</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "reports"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/reports"}
              >
                <MdHowToReg
                  className={
                    screen.screen === "reports"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Reports (Flags)</p>
              </Link>
            </li>
            <li>
              <Link
                className={
                  screen.screen === "trending-campaigns"
                    ? "navButton-selected"
                    : "navButton"
                }
                to={"/dashboard/admin/trending-campaigns"}
              >
                <MdHowToReg
                  className={
                    screen.screen === "trending-campaigns"
                      ? "iconButtons-selected"
                      : "iconButtons"
                  }
                />
                <p> Trending campaigns</p>
              </Link>
            </li>
          </ul>
          <div className="navFooter">
            <p>{"Make what you deserve to make from your content "}</p>
          </div>
        </div>
      )}

      <div className="mobileFooter">
        <Link
          to={"/dashboard/admin"}
          className={
            screen.screen === "home" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiFillHome />
        </Link>

        <Link
          to={"/dashboard/admin/creators"}
          className={
            screen.screen === "creators" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <AiOutlineOrderedList />
        </Link>

        <Link
          to={"/dashboard/admin/businesses"}
          className={
            screen.screen === "businesses" ? "iconButtons-selected" : "iconButtons"
          }
        >
          <RiAccountCircleFill />
        </Link>

        
      </div>
      <div className={"headerTool"}>
        <div className="headerLeft">
          <p>{screen.nav}</p>
          <h2>{screen.headerText}</h2>
        </div>
        <div className="headerRight">
          <p>{bodyInfo.firstName + " " + bodyInfo.lastName}</p>
          <img src={bodyInfo.profilePicture.replace("http:","https:")} />
        </div>
      </div>

      {userType !== "creator" && (
        <div className={"dashboardContent"}>
          {screen.screen === "home" ? (
            <AdminHome />
          ) : screen.screen === "creators" ? (
            <Creators />
          ) : screen.screen === "businesses" ? (
            <Businesses/>
          ) : screen.screen === "income-report" ? (
            <IncomeReport/>
          ) : screen.screen === "payment-requests" ? (
            <PaymentRequests />
          ) : screen.screen === "reports" ? (
            <Reports />
          ): screen.screen === "trending-campaigns" ? (
            <TrendingCampaigns />
          ) : screen.screen === "payment_verefication" ? (
            <ListPayment />
          ): screen.screen === "youtube-update" ?(
            <YoutubeUpdates />
          ): screen.screen === "telegram-message"? (
            <TelegramMessage />
          ): screen.screen === "pay-creators" ? (
            <PayCreators />
          ):
          null}
        </div>
      )}
    </div>
  );
}
