import "./style.create_account.css";
import tools from "../../component/assets/images/tools.svg";
import logoBlack from "../../component/assets/images/zeter adv black logo1.png"
import CForm from "../../component/forms/form";
import { formBuilder } from "../../component/forms/prop.form";
import { useState } from "react";
import { getJson } from "../../component/forms/controller.form";
import { createAccountJson } from "./api.model.create_account";
import {
  buildCreatorApiBody,
  checkIfUserNamesExists,
  createAccountApiCall,
  fileUpload,
  photoUpload,
} from "./controller.create_account";
import { Loading } from "../../component/loading/loading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ErrorToast from "../../component/toast/error";
import { Checkbox } from "@mui/material";
export default function CreateAccount() {
  const [imgProfile, setImgProfile] = useState("");

  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [formData, setFormData] = useState<formBuilder[]>([
    {
      label: "First Name",
      name: "firstName",
      value: "",
      type: "TextInput",
      placeholder: "First Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      value: "",
      type: "TextInput",
      placeholder: "Last Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "User Name",
      name: "userName",
      value: "",
      type: "TextInput",
      placeholder: "User Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Password",
      name: "password",
      value: "",
      type: "TextInput",
      placeholder: "Password",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    // {
    //   label: "Date of Birth",
    //   name: "dob",
    //   value: "",
    //   type: "date",
    //   placeholder: "Date of Birth",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    {
      label: "Gender",
      name: "gender",
      value: "",
      type: "dropdown",
      placeholder: "Gender",
      checkListValues: [],
      checkList: ["", "male", "female"],
      onChange: () => { },
      required: true,
    },
    {
      label: "Email",
      name: "email",
      value: "",
      type: "TextInput",
      placeholder: "Email",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      value: "",
      type: "TextInput",
      placeholder: "Phone Number",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    // {
    //   label: "Woreda",
    //   name: "woreda",
    //   value: "",
    //   type: "TextInput",
    //   placeholder: "Woreda",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    // {
    //   label: "Sub City",
    //   name: "subCity",
    //   value: "",
    //   type: "TextInput",
    //   placeholder: "Sub City",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    // {
    //   label: "City",
    //   name: "city",
    //   value: "",
    //   type: "TextInput",
    //   placeholder: "City",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    // {
    //   label: "Active",
    //   name: "isActive",
    //   value: "",
    //   type: "TextInput",
    //   placeholder: "Active",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    // {
    //   label: "Region",
    //   name: "region",
    //   value: "",
    //   type: "TextInput",
    //   placeholder: "Region",
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },
    {
      label: "Profile Picture",
      name: "identificationImage",
      value: "",
      type: "file",
      placeholder: "Profile Picture",
      checkListValues: [],
      onChange: (value) => {
        // value is a file object
        setLoading(true)
        photoUpload(value).then((response: any) => {

          setLoading(false)
          if (response.status === 200 || response.status === 201) {
            setImgProfile(response?.data?.file);
          } else {
            alert(" Somethign went wrong");
          }

        }).catch((err) => {

        })
        console.log("value: ", value);
      },
      required: true,
    },
  ]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage,setErrorMessage] = useState("Some thing went wrong, please check you connnection and try again!");
  const [allowNext,setAllowNext] = useState(true);

  const [socialMediaInfo, setSocialMediaInfo] = useState<formBuilder[][]>([
    [
      {
        label: "Social Media Account Name",
        value: "",
        placeholder: "Social Media Account Name",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
      {
        label: "Social Media Account Link",
        value: "",
        placeholder: "Social Media Account Link",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
      {
        label: "Social Media Subscribers Count",
        value: "58",
        placeholder: "Social Media Subscribers Count",
        checkListValues: [],
        onChange: () => { },
        required: true,
      },
    ],
  ]);
  const handleClose = () => {
    setShowErrorMessage(false);
    // Additional logic for closing the component
  };

  const navigate = useNavigate();

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(3);
  const [errorMsg, setErrorMsg] = useState("");
  const [updatedContent, setUpdatedContent] = useState<formBuilder[]>();
  const [prevUserName,setPrevUserName] = useState("");
  const [prevEmail,setPrevEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  return (

    <div className="createAccount-signup">
      <div className="logoContainer">
        <Link to={"/"}><img className="logo" src={logoBlack} alt="logo" /></Link>


        <div></div>
      </div>
      <div className="createAccount">
        <Loading loading={loading} setLoading={"sef"} />
        {showErrorMessage && <ErrorToast message={errorMessage} onClose={handleClose} />}
        <div className="loginContent">


          <div className={"formWrapper"}>
            <div className="loginTitle">
              <h1>
                SignUp |{" "}
                <a href="/creator_login" style={{ color: "#6968FF" }}>
                  Login
                </a>
              </h1>
            </div>
            <div className="inline-flex rounded-md shadow-sm w-9/12 mx-4 my-4" role="group">
            <Link to={"/create-account"} type="button" className="px-4 py-2 w-1/2 text-sm font-medium text-white bg-primary-color border  rounded-s-lg   focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
              Creator
            </Link>
            <Link to={"/business-create-account"} type="button" className="px-4 py-2 w-1/2 text-sm font-medium text-gray-900 bg-transparent border  rounded-e-lg hover:bg-primary-color hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-700 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
              Business
            </Link>

          </div>
            {errorMsg === "" ? (
              <></>
            ) : (
              <div className="errorContainer">
                <p className="font-bold text-lg">{errorMsg.toString().toLowerCase()}</p>

                <p className="font-bold">are required</p>
              </div>
            )}
            {CForm(
              formData,
              (updatedDate: formBuilder[]) => {
                console.log("updatedDate: ", updatedDate[2].value );
                console.log("updatedContent: ", updatedContent ? updatedContent[2]?.value  : "" )
                if((updatedDate[2].value !== ""  && updatedDate[2].value !== prevUserName) || (updatedDate[6].value.trim() !== "" && updatedDate[6].value.trim()  != prevEmail)  ){
                  setPrevUserName(updatedDate[2].value);
                  setPrevEmail(updatedDate[6].value.trim());
                  updatedDate[2].value  = updatedDate[2].value.trim();
                  checkIfUserNamesExists(updatedDate[2].value.trim(),updatedDate[6].value.trim()).then((res:any) => {
                   if(res.data.status == "failed"){
                     setAllowNext(false);
                    setShowErrorMessage(true);
                      setErrorMessage(res.data.message);
                   }else{ 
                    setAllowNext(true);

                   }
                  }).catch((err)=>{

                  })
                      if(updatedDate[2].value === "test"){
                        // alert("Username is already taken");
                      }
                }
                  setUpdatedContent(updatedDate);
               
              },
              start,
              end
            )}
            <div style={{display:'flex'}}>
              <input type="checkbox" id="terms" name="terms" value="terms" onChange={() => {
                setAgreedToTerms(!agreedToTerms);
              }} />
              <p style={{marginLeft:5}}>{" I Agree to  "}</p>
              <a href="/terms and conditions draft.pdf" target="_blank" style={{color:"#6968FF",marginLeft: 3}}>Terms and Conditions</a>
            </div>

            <div style={{display: agreedToTerms? 'flex' : 'none'}} className={"actions"}>
              {start === 0 ? (
                <></>
              ) : (
                <button
                  onClick={() => {
                    console.log("start: ", end - start - 1, "end: ", start - 1);
                    setStart(start - 4);
                    setEnd(end - 4);
                  }}
                  className="bg-red-400"
                >
                  Back
                </button>
              )}
          {allowNext && 
              <button
                onClick={async () => {
                  if (end+4 < formData.length) {
                    var inputs = getJson(updatedContent || formData);
                    const valuesArray = Object.values(inputs);
                    const keysArray = Object.keys(inputs);

                    const extractedValues = valuesArray.slice(start, end + 1);
                    const extractedKeys = keysArray.slice(start, end + 1);
                    const emptyStringIndexes: any = [];
                    extractedValues.forEach((value, index) => {
                      if (value === "") {
                        emptyStringIndexes.push(index);
                      }
                    });

                    console.log("empty sting values: ", extractedValues);

                    if (emptyStringIndexes.length === 0) {
                      setErrorMsg("");
                      setStart(start + 4);

                      setEnd(end + 4);
                    } else {
                      var errorsMessage: string = "";
                      emptyStringIndexes.forEach((value: any, index: number) => {
                        errorsMessage += extractedKeys[value] + " ";
                      });
                      setErrorMsg(errorsMessage);
                    }
                  } else {
                    // check if all fields are filled
                    var inputs = getJson(updatedContent || formData);
                    const valuesArray = Object.values(inputs);
                    const keysArray = Object.keys(inputs);

                    const extractedValues = valuesArray.slice(start, end + 1);
                    const extractedKeys = keysArray.slice(start, end + 1);
                    const emptyStringIndexes: any = [];
                    extractedValues.forEach((value, index) => {
                      if (value === "") {
                        emptyStringIndexes.push(index);
                      }
                    });
                    if (emptyStringIndexes.length === 0) {
                      var newJson = getJson(updatedContent || formData);
                      newJson.identificationImage = imgProfile;
                      var content = buildCreatorApiBody(newJson);
                      setLoading(true);
                      var response: any = await createAccountApiCall(content);
                      setTimeout(() => {
                        setLoading(false);
                        if (response.status === 200 || response.status === 201) {
                          navigate("/creator_login");
                        } else {
                          setLoading(false);
                          setShowErrorMessage(true);
                          console.log("response2: ", response);
                          setErrorMessage(response.response.data.message);
                        }
                      }, 4000);

                      
                    } else {
                      var errorsMessage: string = "";
                      emptyStringIndexes.forEach((value: any, index: number) => {
                        errorsMessage += extractedKeys[value] + " ";
                      });
                      setErrorMsg(errorsMessage);
                    }

                  
                  }
                }}
                className={"roundButton bg-green-400"}
              >
                {end+4 < formData.length ? "Next" : "Submit"}
              </button>
              }
              {!allowNext &&
                <span style={{color:'red'}}>{errorMessage}</span>
              }
            </div>
          </div>

        </div>
        <div className="loginDecore">
          <img src={tools} alt="tools" />
        </div>
      </div>
    </div>
  );
}
