

import { columnsStateInitializer } from "@mui/x-data-grid/internals";
import { View } from "lucide-react";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import axios from "axios";
import { url } from "../../../util/constant";
import { promises } from "dns";
import axiosInstance from "../../../axiosConfig";

export function getColumns(): GridColDef[] {    
 const columns: GridColDef[] = [
  { field: 'paymentStatus', headerName: 'Status', width: 90,
  renderCell: (params:any) => {
              
      return(
          <span className="paymentStatus" >
              {params.value}
          </span>
      );   
   }},
    { field: 'paymentDate', headerName: 'paymentDate', width: 230,
  renderCell: (params:any) => {
              
      return(
          <span className="description" >
              {params.value}
          </span>
      );   
   }},
    { field: 'paymentMethod', headerName: 'paymentMethod', width: 100 },
    { field: 'paymentAmount', headerName: 'paymentAmount', width: 100 },
    { field: 'paymentBank', headerName: 'paymentBank', width: 100 },
    { field: 'transactionNumber', headerName: 'transactionNumber', width: 140 },
    { field: '', headerName: 'Action', width: 230,
    renderCell: (params:any) => {
                
        return(
          params.row.paymentStatus == "pending" ?
            <div style={{display: 'flex',flexDirection:'row', justifyContent: 'space-between'}}> 
            <span onClick={()=>{
                axiosInstance.get(`${url}/campaign/approve/business/payment/${params.row.id}`).then((res)=>{
                  alert("Successfully validated");
                }).catch((err)=>{
                  console.log(err);
                })
              }} style={{padding: 5,cursor:'pointer', borderRadius: 5, width: 70,margin:5,color:'white', backgroundColor: 'green'}} >
                  {"Validate"}
              </span>
  
              <span style={{padding: 5,cursor:'pointer', borderRadius: 5, width: 70,margin:5,color:'white', backgroundColor: 'red'}} >
                  {"Invalidate"}
              </span>
              </div> :
              <span>
              </span>
          
        
        );   
     }}
  ];

return columns;
}

export async function getRows(): Promise<any> {
  try {
    var res:any[] = await getCampaignList() as [];
    console.log("CONTENT: ", res);
  return res;
  } catch (error) {
    console.log("ERRRR: ", error);
    return [];
  }

  
}

export function getCampaignList(){
  // var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;

    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/campaign/payment/business/list')
          .then(function (response) {
            console.log("TEST: " ,response.data);
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}


export function getCampaignVideoDetail(creatorId: number , campaignId: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+`/campaign/creator/${creatorId}/campaign/${campaignId}`,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}

export function getYouTubeVideoId(url:string): string{
  // Regular expression to match YouTube video ID
  var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  var match = url.match(regExp);

  if (match && match[1]) {
      var id = match[1]; // return the video ID
      return 'https://www.youtube.com/embed/'+id;
  } else {
      return ""; // return null if the URL is not a valid YouTube URL
  }
}


export function getCampaignDetail(id: number){
  var userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
    return new Promise((resolve, reject) => {
        axiosInstance.get(url+'/business/campaign/'+id,{
          headers: {
              "Authorization": "Bearer " + userInfo   
          }
      })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log(error);
          resolve(error);
          });
        });
}
