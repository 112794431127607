import styled from "styled-components";


export interface callToActionProp{
    message: string;
    title: string;
    color: string;
    actionButton: string;
    actionFunction: ()=>void;
}


export default function CallToAction(data: callToActionProp){

    return(
        <div className="callToAction">
            <div className="callToActionContainer" style={{width:'100%',background:`${data.color}`,color:'white'}}>
                <div className="callToActionContent">
                    <h2 style={{color:'white',fontWeight:'bold'}}>{data.title}</h2>
                    <p style={{fontSize: 17}}>{data.message}</p>
                    <button className="callToActionBtn" onClick={() => {
                        data.actionFunction();
                    }} style={{background:'#090D25',padding:10,borderRadius:5,margin:5}}>{data.actionButton}</button>
                </div>
            </div>
        </div>
    )
}

const popUpWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;