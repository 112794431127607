import React from 'react';
import { handlePlanClick } from '../../../../util/campaign/createCampaign';

interface Props {
    plan: string;
    setPlan: React.Dispatch<React.SetStateAction<string>>;
    setPages: React.Dispatch<React.SetStateAction<number>>;
}

const PlanSelection: React.FC<Props> = ({ plan, setPlan, setPages }) => {
    return (
        <div className="fadeIn">
            <p className='question'> Select Campaign Plan</p>
            <div className='youTubeTelegram'>
               
                 <button className={plan === "standard" ? "standardButton standardButtonClicked" : "standardButton"} onClick={() => handlePlanClick("standard", setPlan, setPages)}>Standard

                    <div className="descriptionSection">
<p style={{fontSize: 13}}> This plan provides essential tools for advertisers to reach a large audience across social platforms, ideal for small to mid-sized businesses starting their ad campaigns. </p>
                        </div>
                 </button>
               
                <button className={plan === "plus" ? "plusButton plusButtonClicked" : "plusButton"} onClick={() => handlePlanClick("plus", setPlan, setPages)}>Zeter Plus

                <div className="descriptionSection">
<p style={{fontSize: 13}}> offers more customization with both advertisement type and selection of influencers. It’s best for businesses looking for extensive advertising efforts and deeper audience engagement.</p>
                        </div>

                </button>
            <button className={plan === "premium" ? "premiumButton premiumButtonClicked" : "premiumButton"} onClick={() => handlePlanClick("premium", setPlan, setPages)}>Premium
            <div className="descriptionSection">
                <p style={{fontSize: 13}}>Coming Soon</p>
<p style={{fontSize: 13}}> includes marketing strategy, content creation, influencer management, and account handling, all managed by Zeterads</p>
                        </div>
            </button>
            </div>
        </div>
    );
};

export default PlanSelection;
