import { useEffect, useState } from "react";
import Table from "../../../component/table/table";
import CountDownTimer from "../../dashboard/orders/accepted_orders/countDownTimer";
import { DownloadCsvFromArrayJson, getPayDayInfo, getPaymentRowData, payCreators, paymentColumn } from "./controller.payCreators";


export const PayCreators = () => {

    const [paymentData,setPaymentData] = useState<any>({});
    const [payDayInfo,setPayDayInfo] = useState<any>({});

    useEffect(()=>{
        getPaymentRowData().then((res)=>{
            console.log(res);
            setPaymentData(res);
        }).catch((err)=>{
            alert("Error in fetching data");
        });

        getPayDayInfo().then((res)=>{
            console.log("PAYDAYINFO: ",res);
            setPayDayInfo(res);
        }).catch((err)=>{
            alert("Error in fetching data");
        }
        )
    },[])

    return (
        <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
            <div style={{display:'flex', width: '95%',justifyContent:'center', alignSelf:'center',borderRadius:5}}>
            <div className="hoverCard" style={{width:'30%'}}>
                <h1> Total Payment </h1>
                <h3>{parseInt(paymentData.totalPayment).toLocaleString()}</h3>
                    </div>
                <div className="hoverCard" style={{width:'30%'}}>
                <h1> Payment Dates  </h1>
                <hr></hr>
                {
                    payDayInfo["list_of_payment"]?.map((item:any)=>{
                        return(
                            <>
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <h4> {item.payment_date} </h4>
                                <h4> {item.payment_status == "NOT PAID"? "NOT PAID" : item.payment_amount} </h4>
                            </div>
                                <hr></hr>
                                </>
                        )
                    })
                }
                    </div>

                    <div className="hoverCard" style={{width:'30%'}}>
                <h1> Next Payment Date </h1>
                {payDayInfo.current_month &&
                
                <CountDownTimer targetDates={new Date(payDayInfo?.current_month?.payment_date)} />
                }

                    </div>
            </div>
            
            
            <div className="bg-white p-8 rounded-md w-[95%] m-auto">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <h1 style={{ fontWeight: 900 }}>Creator List</h1>
                        <h2>List of creators to be payed</h2>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <button 
                        onClick={()=>DownloadCsvFromArrayJson(paymentData.listOfCreators, "payment.csv")}
                        style={{ backgroundColor: '#090D25', borderRadius: 5, color: 'white', padding: 5, margin: 5 }}>Download CSV</button>
                        <button 
                            onClick={()=>{
                                payCreators();
                            }}
                        style={{ backgroundColor: '#090D25', borderRadius: 5, color: 'white', padding: 5, margin: 5 }}>Payed</button>

                    </div>

                </div>
                <Table columns={paymentColumn} data={paymentData?.listOfCreators || []} />

            </div>
        </div>
    );
}