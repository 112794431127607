import React from 'react';
import CBEQr from '../../../../component/assets/CBEQR.jpg';

interface Props {
    expectedView: string;
    setPaymentInfo: (value: any) => void;
    campaignId: number;
    totalPrice: number;
}

const TotalPaymentSection: React.FC<any> = ({setPaymentInfo,campaignId,totalPrice}) => {


    return (
        <div className="fadeIn">
            <p className='question'>  Pay & Verify</p>
            <p style={{textAlign: 'start', color:'gray', fontSize: 17, marginBottom: 20}}> <b style={{color:'black'}}>{totalPrice} Birr</b> is required to continue the campaign  </p>
            <p style={{textAlign: 'start', color:'gray', fontSize: 17, marginBottom: 20}}>You can pay using the QR code below, once you have paid enter the code below to verify your payment </p>
          <div style={{display: 'flex' , flexDirection: 'row'}}>
            <img src="https://addisfortune.news/wp-content/uploads/2022/02/CBE-logo.jpg" alt="Telebirr" width={120} height={100} />
           </div>
            <div className="payment-container-buisness">
              <div className="qr-container">
              <div>
                <h3>1000613933763</h3>
            <img src={CBEQr} alt="QR Code" width={200} height={200} />

                </div>
                </div>
                <div className='rightSide-payemt'>
                <h3>Transaction Code</h3>
                <input type='text' onChange={(e)=>{
                    setPaymentInfo({
                      "campaignId": campaignId,
                      "paymentAmount": "100",
                      "paymentBank": "TELEBIRR",
                      "transactionNumber": e.target.value,
                    })
                  
                }} placeholder="Enter the code here" className="verfication-code" />

                </div>
            </div>

       

        </div>
    );
};

export default TotalPaymentSection;
