import { useState, useEffect } from "react";
import { getCampaignVideoDetail, getYouTubeVideoId } from "./controller.campaign";
import { useParams, useLocation } from "react-router-dom";
import TelegramEmbed from "../../dashboard/orders/video_uploader/telegram/embed_telegram";
import ChatBox from "../../../component/chat_box/chatBox";
import tgBackground from "../../../component/assets/images/telegramBackground.png";
import CountDownTimer from "../../dashboard/orders/accepted_orders/countDownTimer";

interface CampaignOrder {
  id: number;
  creatorId: number;
  campaignId: number;
  socialMediaId: number;
  nicheId: number;
  videoUrl: string;
  viewCount: number | null;
  commentCount: number | null;
  likeCount: number | null;
}

export default function CampaignOrderDetail(order: any) {
  const [loadingVideoDetail, setLoadingVideoDetail] = useState<boolean>(false);
  const [campaignVideoDetail, setCampaignVideoDetail] = useState<any>();
  const [videoUrl, setVideoUrl] = useState<string>("");
  const param = useParams();
  const stateData = useLocation();

  useEffect(() => {
    const creatorId: number = stateData.state.creatorId;
    const campaignId: number = parseInt(param.id ?? "0");
    const socialMediaAccountId: number = parseInt(stateData.state.socialMediaAccountId ?? "0");
    console.log("orderorderorder:  ", order);
    console.log("creator_id: ", creatorId);
    console.log("campaign_id: ", campaignId);
    console.log("socialMediaAccountId: ", socialMediaAccountId);

    getCampaignVideoDetail(creatorId, campaignId,socialMediaAccountId)
      .then((res: any) => {
        console.log("RRRRRRRRR: ", res);
        setCampaignVideoDetail(res);
        var embedUrl: string = getYouTubeVideoId(res?.videoUrl);
        setVideoUrl(embedUrl);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  if(campaignVideoDetail?.socialMediaId === 3){

    return(
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
          <div style={{ display: 'flex',width:'90%',alignSelf:'center' ,borderRadius:10,justifyContent:'center'}}>
              <div className="hoverCard">
                <h3>{campaignVideoDetail.totalPrice + " ETB"}</h3>
                <h2 style={{  borderRadius: 5 }}>Total Price</h2>
              </div>
              <div className="hoverCard">
                <h3>{campaignVideoDetail.days + " Days"}</h3>
                <h2 style={{  borderRadius: 5 }}>Total Days</h2>

              </div>
              <div className="hoverCard">
                {
                      (Date.now() - parseInt(campaignVideoDetail[campaignVideoDetail.length - 1]?.timePosted) * 1000) >= 24 * 60 * 60 * 1000 ?

                  <h3 style={{ color: 'red',textTransform:'uppercase' }}>compeleted</h3>
                  :
                <h3 style={{textTransform:'uppercase'}}>{campaignVideoDetail.status}</h3>

                }
                <h2>Status</h2>
              </div>
            </div>
        {/* <h1>Watch Your Ads here </h1>  */}
        <div className="hoverCard" style={{ width: '88%',height:270,justifyContent:'center',display:'flex',flexDirection:'column',background:'radial-gradient(circle, rgba(9,13,37,0.3449754901960784) 0%, rgba(9,13,37,1) 92%)' }}>
        <CountDownTimer targetDates={new Date(parseInt(campaignVideoDetail.telegramPosts[campaignVideoDetail.telegramPosts.length - 1]?.timePosted) * 1000 + 24 * 60 * 60 * 1000)} />

      </div>
        <div style={{ display: 'flex',width:'90%',alignSelf:'center' ,borderRadius:10,justifyContent:'center'}}>

      <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center',padding:10,margin:10,backgroundImage:'url("'+tgBackground +'")',borderRadius:10,flexWrap:'wrap',overflow:'scroll'}}>
           {campaignVideoDetail.telegramPosts.map((res:any,index:number) => (
            <div style={{display:'flex',flexDirection:'column',margin:15}}>
                 <TelegramEmbed index={index} link={res.videoUrl.split("t.me/")[1]} />
            <span style={{backgroundColor:'#33AFED',padding:5,margin:10,borderRadius: 5,width:300}}>Hours On Top {res?.hoursOnTop}</span>

            </div>

           ))

           }
         </div>
      </div>
      <ChatBox campaignId={161} userId={2} />
      </div>
    )
  }

  return (
    <div className="singleOrder">
      <div className="hoverCard">
        <>
          <h3>{parseInt(campaignVideoDetail?.viewCount).toLocaleString() ?? "NA"}</h3>
          <p>Current View</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{parseInt(campaignVideoDetail?.likeCount).toLocaleString() ?? "NA"}</h3>
          <p>Like Count</p>
        </>
      </div>

      <div className="hoverCard">
        <>
          <h3>{parseInt(campaignVideoDetail?.commentCount).toLocaleString() ?? "NA"}</h3>
          <p>Comment Count</p>
        </>
      </div>

      <div className="singleOrder">
        {
          videoUrl?.includes("youtube") ?
          <iframe
            width="83%"
            height="615"
            style={{marginLeft: '20px'}}
            src={videoUrl? videoUrl.replace("http://","https://"): ""}
            title="YouTube Video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

            allowFullScreen
          ></iframe>
          :
          <iframe
          width="83%"
          height="615"
          src={videoUrl? videoUrl.replace("http://","https://") : ""}
          title="YouTube Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"

          allowFullScreen
        ></iframe>
        }
       
      </div>
    </div>
  );
}
