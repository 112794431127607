

import { Link, useNavigate, useNavigation } from "react-router-dom";
import "./style.google_oauth.css";

import { BiLogoYoutube } from "react-icons/bi";
import { useEffect } from "react";
import { getRefershToken } from "./controller.google_oauth";
export default function GoogleAuth(){
    const navigator = useNavigate();
    //http://127.0.0.1:3000/dashboard/youtube_auth?code=4/0AQlEd8w3vf-pKfwEoa6S5KJeiYI90uqN_bSucVl0PcWMzeFFzWPxE47GS4JJsvMN4YSdnA&scope=https://www.googleapis.com/auth/youtube.channel-memberships.creator%20https://www.googleapis.com/auth/youtube.force-ssl%20https://www.googleapis.com/auth/youtubepartner-channel-audit%20https://www.googleapis.com/auth/yt-analytics-monetary.readonly%20https://www.googleapis.com/auth/youtube.upload%20https://www.googleapis.com/auth/youtube.readonly%20https://www.googleapis.com/auth/youtubepartner%20https://www.googleapis.com/auth/yt-analytics.readonly%20https://www.googleapis.com/auth/youtube
    useEffect(() => {
        const url = new URL(window.location.href);
        
        // Get values from query parameters
        const code = url.searchParams.get('code');
        console.log("CODE: ", code);
        if(code){
            getRefershToken(code).then((res: any)=>{
                // alert("Get Refersh Token", console.log(res));
                console.log("Get Refersh Token", res);
                //navigator("http://127.0.0.1:3000/dashboard/profile");
                // since the navigator is not working so i am using window.location.href
                // check if the website is running on local or on server
                    // if(window.location.href.includes("127.0.0.1")){
                    //     window.location.href = "http://127.0.0.1:3000/dashboard/profile";
                    // }else{

                    //     window.location.href = "https://zeterads.com/dashboard/profile";
                    // }
            }).then((err: any)=>{
                console.log(err)
                //alert(JSON.stringify(err));
            });

           
        }else{  

           // navigator("http://127.0.0.1:3000/dashboard/profile");
           window.location.href = "https://zeterads.com/dashboard/profile";
            
        }
    
    },[])
    return(
        <div className="googleAuth">

                <div className="middleContaint">
                    <div className="middleHeader"> 
                    <BiLogoYoutube className="iconMiddleHeader" />
                <h1> Successfully Logedin to Youtube </h1>
                </div>
                <Link to="/dashboard/profile" >
                <button className="primaryButton"> Continue To Dashboard </button>
                </Link>
                </div>

        </div>
    );
}