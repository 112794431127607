export default function AdminHome() {


    return (
        <div style={{display:'flex',flexWrap:'wrap',margin:20}}>

            <div className="hoverCard">
                <h1>Active Campaigns</h1>
            </div>

            <div className="hoverCard">
                <h1>Completed Campaigns</h1>
            </div>

            <div className="hoverCard">
                <h1>Total Money</h1>
            </div>


            <div className="hoverCard">
                <h1>Youtube Campaigns</h1>
            </div>



            <div className="hoverCard">
                <h1>Telegram Campaigns</h1>
            </div>



            <div className="hoverCard">
                <h1>Tiktok Campaigns</h1>
            </div>

            <div className="hoverCard">
                <h1>Total Creators</h1>
            </div>

            <div className="hoverCard">
                <h1>Total Businessess</h1>
            </div>


        </div>
    )
}