import React, { useEffect } from 'react';

const TelegramEmbed = ({index,link}:any) => {
  useEffect(() => {
    const scriptId = 'telegram-embed-script'+index;
    if (document.getElementById(scriptId)) {
      // If the script is already present, return
      return;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-post', link); // Update this to your actual Telegram post
    script.setAttribute('data-width', '100%');
  
        document.getElementById('telegram-embed'+index)?.appendChild(script);
    
  }, []);

  return (
    <div>
      <div id={"telegram-embed"+index+""}></div>
    </div>
  );
};

export default TelegramEmbed;
