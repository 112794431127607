import React, { useState } from "react";

type Creator = {
  id: number;
  user: number;
  firstName: string;
  lastName: string;
  dob: string;
  gender: string;
  email: string;
  verfiedEmail: string;
  phoneNumber: string;
  woreda: string;
  subCity: string;
  city: string;
  isActive: number;
  telegramId: string;
  region: string;
  bankName: string;
  bankUserName: string;
  bankAccountNumber: string;
  tinNumber: string;
  licence?: string | null;
  about: string;
  rate: string;
  productionCost: string;
  identificationImage: string;
  creatorcontentforacampaigns: any[];
};

type SocialMediaAccount = {
  id: number;
  creatorId: number;
  socialMediaId: number;
  accountName: string;
  accountLink: string;
  subCount: number;
  avgView: string;
  avgPostPerMonth: string;
  refershToken?: string | null;
  quota_point?: string | null;
  channelOwnerId?: string | null;
  rate: number;
  channelId?: string | null;
  socialMediaAccount: any;
  status: string;
  nicheId?: number | null;
  creator: Creator;
};

type CreatorInACampaign = {
  id: number;
  creatorId: number;
  socialMediaAccountId: number;
  campaignId: number;
  totalView: number;
  totalPrice: number;
  actualView: number;
  status: string;
  creatorInACampaignId?: number | null;
  days?: string | null;
  hours_on_top?: number | null;
  is_pined?: boolean | null;
  socialMediaAccount: SocialMediaAccount;
  creatorcontentforacampaigns: any[];
};

type Campaign = {
  id: number;
  businessId: number;
  contentDescription: string;
  shortVideoUrl: string | null;
  startDate: string;
  endDate: string;
  campaignStatus: string;
  socialMediaLinks?: string | null;
  postDescription?: string | null;
  estimatedBudget?: number | null;
  duration?: string | null;
  diversify?: string | null;
  totalViews?: number | null;
  createdDate?: string | null;
  socialMediaId?: number | null;
  creatorinacampaigns: CreatorInACampaign[];
};

export type BusinessData = {
  id: number;
  user: string;
  name: string;
  email: string;
  phone: string | null;
  address: string;
  businessLicense: string;
  campaigns: Campaign[];
};

const BusinessAccordion: React.FC<{ business: BusinessData }> = ({ business }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasCampaigns = business.campaigns.length > 0;

  return (
    <div
      className={`border border-gray-300 rounded-md mb-4 shadow ${hasCampaigns ? "bg-green-100" : "bg-white"
        }`}
    >
      <div
        className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 hover:bg-gray-200"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="text-left">
          <h3 className="text-lg font-semibold">{business.name}</h3>
          <p className="text-sm text-gray-500">{business.email}</p>
          {hasCampaigns && (
            <p className="text-sm text-green-600 font-bold">CAMPAIGNS AVAILABLE</p>
          )}
        </div>
        <span className="text-gray-600">{isOpen ? "▲" : "▼"}</span>
      </div>

      {isOpen && (
        <div className="p-4 bg-white text-left">
          <div className="mb-4">
            <p className="text-sm">
              <strong>Phone:</strong> {business.phone || "N/A"}
            </p>
            <p className="text-sm">
              <strong>Address:</strong> {business.address}
            </p>
            <p className="text-sm">
              <strong>Business License:</strong> {"N/A"}
            </p>
          </div>

          {hasCampaigns ? (
            <div>
              <h4 className="text-lg font-semibold mb-2">Campaigns</h4>
              {business.campaigns.map((campaign) => (
                <div
                  key={campaign.id}
                  className="p-4 mb-2 border border-gray-200 rounded-md shadow text-left"
                >
                  <p>
                    <strong>Description:</strong> {campaign.contentDescription}
                  </p>
                  <p>
                    <strong>Status:</strong> {campaign.campaignStatus}
                  </p>
                  <p>
                    <strong>Start Date:</strong> {campaign.startDate}
                  </p>
                  <p>
                    <strong>End Date:</strong> {campaign.endDate}
                  </p>
                  {campaign.shortVideoUrl && (
                    <p>
                      <strong>Video:</strong>{" "}
                      <a
                        href={campaign.shortVideoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        View Video
                      </a>
                    </p>
                  )}
                  <div>
                    <h5 className="text-md font-semibold mt-4">Creators</h5>
                    {campaign.creatorinacampaigns.map((creatorCampaign) => (
                      <div
                        key={creatorCampaign.id}
                        className="p-4 mt-2 border border-gray-300 rounded-md shadow"
                      >
                        <p>
                          <strong>Creator:</strong> {creatorCampaign.socialMediaAccount.creator?.firstName}{" "}
                          {creatorCampaign.socialMediaAccount.creator?.lastName}
                        </p>
                        <p>
                          <strong>Account Name:</strong>{" "}
                          {creatorCampaign.socialMediaAccount.accountName}
                        </p>
                        <p>
                          <strong>Account Link:</strong>{" "}
                          <a
                            href={creatorCampaign.socialMediaAccount.accountLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            {creatorCampaign.socialMediaAccount.accountLink}
                          </a>
                        </p>
                        <p>
                          <strong>Total Views:</strong> {creatorCampaign.totalView}
                        </p>
                        <p>
                          <strong>Status:</strong> {creatorCampaign.status}
                        </p>
                        <p>
                          <strong> {"This Creators Content"}</strong>
                        </p>
                        {
                          creatorCampaign?.socialMediaAccount?.creator?.creatorcontentforacampaigns && creatorCampaign?.socialMediaAccount.creator.creatorcontentforacampaigns?.map((content) => (
                            <div

                              className="p-4 mt-2 border border-gray-300 rounded-md shadow bg-gray-100 "
                            >
                              {content.socialMediaId == 1 ?
                                <>
                                  <p>
                                    <strong>Content:</strong> <a href={content.videoUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View Video</a>
                                  </p>
                                  <p>
                                    <strong> View Count: </strong> {content.viewCount}
                                  </p>
                                  <p>
                                    <strong> Comment Count:  </strong> {content.commentCount}
                                  </p>
                                  <p>
                                    <strong> Like Count: </strong> {content.likeCount}
                                  </p>
                                </> :
                                <>
                                  <p>
                                    <strong>Content:</strong>  <a href={content.videoUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">View Post</a>
                                  </p>
                                  <p>
                                    <strong>Time Posted:</strong> {content.timePosted  ? new Date(content.timePosted * 1000 ).toISOString() : "N/A"}
                                  </p>
                                </>
                              }

                            </div>
                          ))

                        }
                        {/* "socialMediaId": 1,
                                            "nicheId": 1,
                                            "videoUrl": "https://www.youtube.com/watch?v=C3QfwT7CqoI",
                                            "viewCount": 5960822,
                                            "commentCount": 4196,
                                            "likeCount": 80382, */}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-sm text-gray-500">No campaigns available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default BusinessAccordion;
