import { useEffect, useState } from "react";
import "../../dashboard/style.mainDashboard.css";
import "../../dashboard/style.mobile.mainDashboard.css";
import { CreatorData, getAllCreatorsData, getCreatorData, getCreatorDataAdmin } from "./controller.creators";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import CreatorAccordion from "./CreatorAccordion";

export default function Creators() {
    const [allCreators, setAllCreators] = useState<CreatorData[]>()
    const [creator, setCreator] = useState<CreatorData[]>()
    const [creatorName, setCreatorName] = useState("*")
    const [search, setSearch] = useState(false)
    const next = 1;
    const [current, setCurrent] = useState(0)
    const [total, setTotal] = useState(current + next)
    

    // useEffect(()=>{
    //     getAllCreatorsData(current, next).then((data:CreatorData[]) => {

    //         setAllCreators(data);
            
    //     });
        
    // }, [current])

    useEffect(() => {
        getCreatorDataAdmin(current, next, creatorName,3).then((data:CreatorData[]) => {
                
                setAllCreators(data);
                // setSearch(false)
                
                
            });
    },[])

    useEffect(()=>{
        getCreatorDataAdmin(current, next, creatorName,3).then((data:CreatorData[]) => {

            setCreator(data);
            // setSearch(false)
            
            
        });
        
    }, [search=== true])

    return (
        
     
        <div className="bg-white p-8 rounded-md w-[95%] m-auto">
            {allCreators?.map((creator) =>{
                return(
                    <CreatorAccordion creator={creator as any} />   
            )})}
           
        </div>
       
    )
}


