import { useEffect, useState } from "react";
import { AiOutlineLine } from "react-icons/ai";
import { RiAddLine } from "react-icons/ri";
import axiosInstance from "../../../../axiosConfig";
import { url } from "../../../../util/constant";



export default function EditePaymentComponent({socialMediaAccountId}: any) {
    const [linkType, setLinkType] = useState("");
    const [socialMedia, setSocialMedia] = useState("");
    const [websiteListLocalized, setWebsiteListLocalized] = useState<any[]>([{
        "id": 0,
        "days": "1",
        "hours": "1",
        "with_pin": "0",
        "with_out_pin": "0"
    }]);

    const getPaymentInfoById = (id: number) => {
        // api call to get payment info by id
        let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
        axiosInstance.get(url+"/creator/get_social_media_account_detail/get_payment_plans/"+id,{
            "headers":{
                "Authorization": "Bearer " + userInfo
            }
        }).then((res ) => {
            if(res.data.status === "success"){
            setWebsiteListLocalized(res.data.data);
            }else{
                alert("Something went wrong")
            }
        }).catch((err) => {
            alert("Something went wrong")
        })
    }

    useEffect(() => {
    
        getPaymentInfoById(socialMediaAccountId);
    },[])

    useEffect(()=>{
      //  setPaymentInfo(websiteListLocalized);
    },[websiteListLocalized])

    return (
        <div style={{width: '100%', height: '100vh',margin: 'auto',alignSelf:'center',display:'flex',flexDirection:'column',justifyContent:'flex-start',alignItems:'center'}}>
            <div className="website-linker-container_outer" >
                     <h3> Days </h3>
                      <h3> Hours on top</h3>
                       <h3> With Pin(Birr) </h3>
                        <h3> With out pin(Birr) </h3>
                    </div>
            {websiteListLocalized.map((collectedData: any, index: number) => {

                return (
                    <div className="website-linker-container" key={index}>
                      <input  onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, days: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }} type="number" style={{width:160 , padding: 6, textAlign: 'center',  }} contentEditable={false} value={collectedData.days}  name="days" />
                      <input type="text" color={"gray"} style={{width:160 , padding: 6, textAlign: 'center',  color:'gray' }}  contentEditable={false} value={collectedData.days * 1}  name="days" />
                        <input  value={collectedData['with_pin']} type='number' onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, with_pin: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }}  style={{width:160 , padding: 6, textAlign: 'center',  }} name="with_pin" />
                        <input value={collectedData['with_out_pin']} type='number' onChange={(e) => {
                            setWebsiteListLocalized(prevValue => {
                                const newState = prevValue.map((item, i) =>
                                    i === index ? { ...item, with_out_pin: e.target.value } : item
                                );
                                return newState;
    
                            })

                      }}  style={{width:160 , padding: 6, textAlign: 'center',  }} name="with_out_pin" />


                        <button onClick={() => {
                            setWebsiteListLocalized(prevState => {
                                const newState = [...prevState];
                                
                                newState.splice(index, 1);
                                return newState;
                            });
                        }} className="negative">
                            <AiOutlineLine width={50} height={50} />
                        </button>
                        <button onClick={() => {
                            let prevValue = [...websiteListLocalized];
                            //let toBeRepeated = prevValue[index];
                            
                            prevValue.push({
                                "id": 0,
                                "days": prevValue.length + 1,
                                "hours": prevValue.length + 1,
                                "with_pin": "0",
                                "with_out_pin": "0"
                            });
                            setWebsiteListLocalized(prevValue);

                        }}>
                            <RiAddLine width={50} height={50} />
                        </button>



                    </div>
                )
            })}
            <button style={{marginLeft:0,textAlign:'center',width:200,padding:15,backgroundColor:'#090D25',borderRadius: 5,color:'white'}}
             onClick={() => {
                let userInfo = JSON.parse(localStorage.getItem("UserInfo") || "{}")["access_token"] ;;
                axiosInstance.put(url+"/creator/update_social_media_account_detail/update_payment_plans/"+socialMediaAccountId,websiteListLocalized,{
                    "headers":{
                            "Authorization": "Bearer " + userInfo
                    }
                    
                }).then((res) => {
                    if(res.data.status === "success"){
                        alert("Updated successfully")
                    }else{
                        alert("Something went wrong")
                    }
                }).catch((err) => {
                    alert("Something went wrong")
                }
                )

            }}>Update</button>
        </div>
    )
}